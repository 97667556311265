.calendar-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #ffd8d8;
  padding: 20px;
  font-family: Arial, sans-serif;

  &__back {
    background-color: #fff;
    color: #000;
    border: 1px solid #ccc;
    padding: 10px 20px;
    border-radius: 5px;
    cursor: pointer;
    margin-bottom: 20px;
    transition: background-color 0.3s;

    &:hover {
      background-color: #f0f0f0;
    }
  }

  &__date {
    font-size: 18px;
    font-weight: bold;
    margin-bottom: 20px;
    background-color: #fff;
    color: #000;
    padding: 10px 20px;
    border-radius: 20px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
  }
}

.calendar__services {
  border: none;
  width: 100%;
  max-width: 400px;
  background-color: #fff;
  border-radius: 10px;
  padding: 10px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);

  .react-calendar__navigation {
    display: flex;
    justify-content: space-between;
    margin-bottom: 10px;

    button {
      background: none;
      border: none;
      font-size: 18px;
      cursor: pointer;
      color: #008cba;

      &:hover {
        color: #005f73;
      }
    }
  }

  .react-calendar__month-view__weekdays {
    font-weight: bold;
    text-align: center;

    abbr {
      text-decoration: none;
    }
  }

  .react-calendar__tile {
    text-align: center;
    padding: 10px;
    // margin: 5px;
    background: none;
    border: none;
    border-radius: 50%;
    transition: background-color 0.3s, color 0.3s;

    &:hover {
      background-color: #e0e0e0;
    }
  }

  //   .react-calendar__tile--active {
  //     background-color: #ff4d4d;
  //     color: #fff;
  //   }

  //   .react-calendar__tile--now {
  //     background-color: #008cba;
  //     color: #fff;
  //     border-radius: 50%;
  //   }

  //   .highlight {
  //     background-color: #b56576;
  //     color: #fff;
  //     font-weight: bold;
  //     border-radius: 50%;
  //   }
}
