.nav {
  background: #252C3E;
  width: 30%;
  box-sizing: border-box;
  min-height: 100vh;

  .nav__logo {
    position: fixed;
    padding: 9px 0;
    border-bottom: 1px solid #242424;
  }

  .nav__lists {
    position: fixed;
    margin-top: 82px;
    padding: 0;

    a {
      display: flex;
      align-items: center;
      padding: 12px 19px;
      text-decoration: none;
      color: #FFFFFF;
      font-weight: 400;
      font-size: 14px;
      line-height: 20px;
      transition: 0.3s ease-in-out;
      cursor: pointer;

      &:hover {
        opacity: 0.7;
      }
    }

    img {
      margin-right: 8px;
    }

    svg {
      margin-right: 8px;
    }
  }
}

.nav__button {
  display: none;
}

@media(max-width: 820px) {
  .nav {
    width: 238px;
  }

  .nav__button {
    display: block;
    position: absolute;
    right: 12px;
    top: 0px;
  }

  #menu__toggle {
    opacity: 0;
  }

  .menu__btn {
    display: flex;
    align-items: center;
    position: relative;
    width: 26px;
    height: 26px;
    cursor: pointer;
    z-index: 1;
  }

  .menu__btn>span,
  .menu__btn>span::before,
  .menu__btn>span::after {
    display: block;
    position: absolute;
    width: 100%;
    height: 2px;
    background-color: #3a66e9;
  }

  .menu__btn>span::before {
    content: '';
    top: -8px;
  }

  .menu__btn>span::after {
    content: '';
    top: 8px;
  }

  #menu__toggle:checked~.menu__btn>span {
    transform: rotate(45deg);
  }

  #menu__toggle:checked~.menu__btn>span::before {
    top: 0;
    transform: rotate(0);
  }

  #menu__toggle:checked~.menu__btn>span::after {
    top: 0;
    transform: rotate(90deg);
  }

  #menu__toggle:checked~.menu__box {
    visibility: visible;
    left: 0;
  }

  .menu__btn>span,
  .menu__btn>span::before,
  .menu__btn>span::after {
    transition-duration: .25s;
  }

  .menu__box {
    transition-duration: .25s;
  }

  .menu__item {
    transition-duration: .25s;
  }

  .nav {
    transition: 0.3s;
    transform: translateX(-256px);
    position: absolute;
    z-index: 99999;
  }

  .nav__mobile {
    transform: translateX(0);
  }
}

@media(max-width: 425px) {
  .nav {
    width: 60%;
  }
}