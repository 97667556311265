.order-list {
  background-color: #ffd8d8;
  padding: 20px;
  font-family: Arial, sans-serif;
  // height: 100vw;

  &__back {
    background-color: #fff;
    color: #000;
    border: 1px solid #ccc;
    padding: 10px 20px;
    border-radius: 5px;
    cursor: pointer;
    margin-bottom: 20px;
    transition: background-color 0.3s;

    &:hover {
      background-color: #f0f0f0;
    }
  }

  &__date {
    text-align: center;
    font-size: 18px;
    margin-bottom: 20px;
  }

  .order-card {
    background-color: #fff;
    margin-bottom: 20px;
    border-radius: 10px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
    overflow: hidden;

    &__header {
      background-color: #ff4d4d;
      color: #fff;
      padding: 10px;
      font-size: 16px;
      text-align: center;
    }

    &__body {
      padding: 15px;

      p {
        margin: 5px 0;
      }

      ul {
        padding-left: 20px;
        margin: 5px 0;

        li {
          list-style: disc;
          margin: 5px 0;
        }
      }
    }
  }
}
