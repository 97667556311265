.cleaners-page {
  padding: 20px;
}

.cleaner-card {
  background-color: #ffe4e1;
  padding: 15px;
  margin-bottom: 20px;
  border-radius: 10px;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  flex-wrap: wrap;
}

.cleaner-card__info,
.cleaner-card__passport,
.cleaner-card__actions {
  flex: 1 1 30%;
  margin: 10px;
}

.cleaner-card__info p,
.cleaner-card__passport p {
  margin: 5px 0;
}

.cleaner-card__passport img {
  width: 100px;
  height: auto;
  margin: 5px;
  border: 1px solid #ccc;
  border-radius: 5px;
}

.cleaner-card__actions {
  display: flex;
  flex-direction: column;
}

.btn {
  display: block;
  padding: 10px 15px;
  margin-bottom: 10px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.btn--percentage {
  background-color: #d1c4e9;
}

.btn--salary {
  background-color: #ce93d8;
}

.btn--accept {
  background-color: #ef5350;
  color: #fff;
}

.btn--delete {
  background-color: #5d4037;
  color: #fff;
}

/* Модальное окно */
.modal {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 1000;
  display: flex;
  justify-content: center;
  align-items: center;
}

.modal-content {
  position: relative;
  background: none; /* Убираем фон */
  padding: 0;
  border: none;
  max-width: 90%;
  max-height: 90%;
  overflow: hidden;
}

.modal-content img {
  max-width: 100%;
  max-height: 100%;
  border-radius: 10px;
}

.modal-close {
  position: absolute;
  top: -10px;
  right: -10px;
  background: #ff5c5c;
  color: white;
  border: none;
  border-radius: 50%;
  width: 30px;
  height: 30px;
  font-size: 16px;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
}
