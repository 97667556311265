.payment-table {
  table {
    width: 100%;
    border-collapse: collapse;
    font-family: Arial, sans-serif;

    th,
    td {
      text-align: left;
      padding: 10px;
    }

    thead {
      background-color: #f9f9f9;
      border-bottom: 2px solid #e0e0e0;

      th {
        font-weight: bold;
        font-size: 14px;
        color: #555;
      }
    }

    tbody {
      tr {
        border-bottom: 1px solid #e0e0e0;
        transition: background-color 0.3s ease;

        &:hover {
          background-color: #f5f5f5;
        }

        td {
          font-size: 14px;
          color: #333;
        }
      }

      .payment-table__status {
        padding: 5px 10px;
        border-radius: 5px;
        font-size: 12px;
        font-weight: bold;

        &--paid {
          background-color: #ffeeba;
          color: #856404;
        }

        &--complete {
          background-color: #d4edda;
          color: #155724;
        }
      }
    }
  }
}
