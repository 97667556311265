.order-card {
  display: flex;
  flex-wrap: wrap;
  margin: 22px 0;

  &__section {
    flex: 1;
    margin: 2px;
    padding: 12px;
    border: 1px solid #f0f0f0;
    border-radius: 8px;

    &--user {
      background-color: #ffecec;
    }

    &--address {
      background-color: #f9f9ff;
    }

    &--details {
      background-color: #f0f9ff;
    }

    &--cost {
      background-color: #ffecec;
    }
  }

  &__services {
    padding: 0;
    list-style: none;
    li {
      padding: 3px 0;
    }
  }

  &__title {
    font-size: 20px;
    color: #333;
    margin-bottom: 10px;
  }

  &__subtitle {
    font-size: 18px;
    color: #555;
    margin-bottom: 8px;
  }

  &__user-name {
    font-size: 16px;
    margin-bottom: 5px;

    &-role {
      font-size: 14px;
      color: #888;
    }
  }

  &__user-contact,
  &__user-email,
  &__date {
    font-size: 14px;
    color: #666;
  }

  &__cleaner-selector {
    display: flex;
    flex-direction: column;
    gap: 5px;
    margin-top: 10px;
  }

  &__cleaner {
    display: flex;
    align-items: center;

    input {
      width: auto !important;
      margin-right: 5px;
    }
  }

  &__save-button {
    margin-top: 10px;
    padding: 8px 16px;
    font-size: 14px;
    background-color: #755fff;
    color: #fff;
    border: none;
    border-radius: 5px;
    cursor: pointer;
  }

  &__price {
    font-size: 24px;
    color: #000;
    font-weight: bold;
  }

  &__discount {
    color: green;
    font-weight: bold;
  }

  &__status {
    display: flex;
    flex-direction: column;
    gap: 5px;

    label {
      display: flex;
      align-items: center;

      input {
        width: auto !important;
        margin-right: 5px;
      }
    }
  }

  &__actions {
    margin-top: 10px;
    display: flex;
    gap: 10px;

    &-button {
      padding: 8px 16px;
      border-radius: 5px;
      font-size: 14px;
      cursor: pointer;

      &:first-of-type {
        background-color: #f9bc2f;
        color: #fff;
      }

      &--delete {
        background-color: #ff4d4f;
        color: #fff;
      }
    }
  }
}
