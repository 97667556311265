.revenue-container {
  padding: 4px;
  background-color: #ffd8d8;
  font-family: Arial, sans-serif;

  &__back {
    background-color: #fff;
    color: #000;
    border: 1px solid #ccc;
    padding: 10px 20px;
    border-radius: 5px;
    cursor: pointer;
    margin-bottom: 20px;
    transition: background-color 0.3s;

    &:hover {
      background-color: #f0f0f0;
    }
  }

  &__title {
    text-align: center;
    font-size: 24px;
    margin-bottom: 20px;
    color: #333;
  }
}

.revenue-filters {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 10px;
  margin-bottom: 20px;

  &__month,
  &__day {
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 5px;
    font-size: 14px;
    width: 150px;

    @media (max-width: 425px) {
      width: 100%;
      font-size: 12px;
    }
  }
}

.revenue-table {
  width: 100%;
  border-collapse: collapse;
  background-color: #fff;
  border-radius: 10px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
  overflow: hidden;

  @media (max-width: 425px) {
    display: block;
    overflow-x: auto; /* Добавляем горизонтальную прокрутку */
    white-space: nowrap; /* Убираем перенос строк */
    border-radius: 0; /* Убираем лишние закругления */
  }

  thead {
    background-color: #008cba;
    color: #fff;

    th {
      padding: 10px;
      text-align: left;
      font-size: 16px;

      @media (max-width: 425px) {
        font-size: 14px; /* Уменьшаем шрифт */
      }
    }
  }

  tbody {
    tr {
      &:nth-child(even) {
        background-color: #f9f9f9;
      }

      &:hover {
        background-color: #f1f1f1;
      }
    }

    td {
      padding: 10px;
      font-size: 14px;
      color: #333;
      text-align: left;

      @media (max-width: 425px) {
        font-size: 12px; /* Уменьшаем шрифт */
        padding: 5px; /* Уменьшаем отступы */
      }
    }
  }
}
