.side-menu {
  width: 250px;
  background-color: #fff;
  padding: 20px;
  display: flex;
  flex-direction: column;
  border-right: 1px solid #e0e0e0;

  /* Профиль */
  &__profile {
    display: flex;
    align-items: center;
    margin-bottom: 20px;

    &-avatar {
      width: 50px;
      height: 50px;
      border-radius: 50%;
      margin-right: 10px;
    }

    &-info {
      display: flex;
      flex-direction: column;

      &-role {
        font-size: 12px;
        color: #888;
        text-transform: uppercase;
        margin-bottom: 5px;
      }

      &-name {
        font-size: 16px;
        color: #333;
      }
    }
  }

  /* Навигация */
  &__nav {
    &-title {
      font-size: 14px;
      color: #555;
      margin-bottom: 10px;
      text-transform: uppercase;
    }

    &-list {
      padding: 0; /* Убираем отступы */
      margin: 0; /* Убираем отступы */
      list-style: none; /* Убираем маркеры */

      &-item {
        border-radius: 5px;
        a {
          display: flex;
          align-items: center;
          padding: 10px;
          border-radius: 5px;
          color: #555;
          cursor: pointer;
          transition: background-color 0.3s ease;
          color: inherit;
          text-decoration: none;
          font-size: 14px;
        }
        &:hover {
          background-color: #13010124; /* Ховер на элемент списка */
        }

        &--active {
          background-color: #13010124; /* Фон активного элемента */
        }

        &-icon {
          margin-right: 10px;
          font-size: 16px;
        }
      }
    }
  }

  /* Секция клинеров */
  &__section {
    margin-top: 20px;
    border-top: 1px solid #e0e0e0;

    &-title {
      font-size: 14px;
      color: #555;
      margin-bottom: 10px;
      text-transform: uppercase;
      display: flex;
      align-items: center;

      &-icon {
        margin-left: 5px;
        font-size: 18px;
        color: #007bff;
        cursor: pointer;
        transition: color 0.3s ease;

        &:hover {
          color: #0056b3; /* Ховер на знак "+" */
        }
      }
    }

    &-list {
      padding: 0; /* Убираем отступы */
      margin: 0; /* Убираем отступы */
      list-style: none; /* Убираем маркеры */

      &-item {
        display: flex;
        align-items: center;
        padding: 10px;
        border-radius: 5px;
        color: #555;
        cursor: pointer;
        transition: background-color 0.3s ease;

        &:hover {
          background-color: #f0f0f0; /* Ховер на элемент списка */
        }

        &-avatar {
          width: 30px;
          height: 30px;
          border-radius: 50%;
          margin-right: 10px;
        }
      }
    }
  }
}
