body {
  margin: 0;
  font-family: 'Poppins', sans-serif;
}
main {
  overflow: hidden;
  width: 100%;
}
.layout__wrapper {
  width: 100%;
}
.app {
  overflow: hidden;
}
.page__wrapper {
  width: 100%;
}
.page__main {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  padding: 12px 48px;
  background: #F6F7F9;
  /* height: 100vh; */
}
.page__main-container {
  width: 684px;
}
.page__main-conteiner-small {
  display: flex;
  padding: 26px 0;
}
.button {
  border: none;
    background: none;
    font-size: 16px;
    color: #4c75f2;
    cursor: pointer;
    display: flex;
    padding: 6px;
}
@media(max-width: 1700px) {
  .page__main-container {
    width: 560px;
  }
}
@media(max-width: 1460px) {
  .page__main-container {
    width: 444px;
  }
}
@media(max-width: 1220px) {
  .page__main-container {
    width: 90%;
  }
  .page__main-conteiner-small {
    justify-content: space-between;
  }
  .page__main {
    justify-content: center;
  }
}
@media(max-width: 960px) {
  .page__main {
    padding: 12px 3px;
    width: 100%;
    box-sizing: border-box;
  }
  .page__main-conteiner-small {
    width: 100%;
    flex-wrap: wrap;
  }
}
@media(max-width: 820px) {
  main {
    width: 100%;
  }
}