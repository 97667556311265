.calendar__wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 320px;
  background-color: #fff;
  padding: 20px;
  border-radius: 15px;
}

.calendar__services {
  width: 320px;
  border: none;

  .react-calendar {
    background-color: #fff;
    border: none;
    border-radius: 15px;
    font-family: "Arial", sans-serif;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);

    &__navigation {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 15px;

      button {
        background: #fff;
        border: none;
        font-size: 18px;
        cursor: pointer;
        color: #333;

        &:hover {
          color: #007bff;
        }
      }

      .react-calendar__navigation__label {
        font-size: 18px;
        font-weight: bold;
        color: #333;
      }
    }

    &__month-view {
      padding: 10px;

      &__weekdays {
        display: flex;
        justify-content: space-around;
        color: #888;
        font-size: 14px;
        margin-bottom: 10px;
        text-transform: uppercase;

        abbr {
          text-decoration: none;
        }
      }

      &__days {
        // display: grid;
        // grid-template-columns: repeat(7, 1fr);

        button {
          height: 40px;
          width: 40px;
          border: none;
          border-radius: 20%;
          background: none;
          font-size: 14px;
          color: #333;
          cursor: pointer;
          transition: all 0.3s;

          &:hover {
            background-color: #f0f0f0;
          }

          &.react-calendar__tile--active {
            background-color: #007bff;
            color: #fff;
          }
        }
      }
    }
  }
}

.calendar__tile--selected {
  background-color: #4a90e2 !important;
  color: #fff;
}

.calendar__tile--in-range {
  background-color: #b3d9ff; /* Светло-синий цвет */
  color: #ffffff;
  border-radius: 4px;
}

.calendar__tile--in-range:hover {
  background-color: #80bfff;
}
