.dashboard {
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #ffd8d8;
  padding: 20px;
  border-radius: 10px;
  font-family: Arial, sans-serif;

  &__header {
    display: flex;
    justify-content: space-between;
    width: 100%;
    background-color: #008cba;
    color: #fff;
    border-radius: 10px;
    padding: 10px 20px;
    margin-bottom: 80px;

    & > div {
      display: flex;
      flex-direction: column;
      align-items: center;

      .icon {
        font-size: 24px;
        margin-bottom: 5px;
      }

      p {
        margin: 0;
        font-size: 14px;
      }

      .count,
      .amount {
        font-size: 20px;
        font-weight: bold;
        margin-top: 5px;
      }
    }
  }

  &__profile {
    background-color: #fff;
    color: #000;
    padding: 20px;
    border-radius: 50%;
    width: 120px;
    height: 120px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 20px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);

    &-info {
      text-align: center;

      p {
        margin: 5px 0;
        font-size: 16px;
        font-weight: bold;
      }
    }
  }

  &__actions {
    display: flex;
    flex-direction: column;
    gap: 10px;
    width: 100%;
    margin-top: 40px;
  }
}
.dashboard__notification {
  text-align: center;
  text-decoration: none;
  color: #fff;
}
.dashboard__button {
  background-color: #fff;
  color: #000;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
  font-size: 16px;
  cursor: pointer;
  transition: background-color 0.3s;
  text-decoration: none;
  text-align: center;

  &:hover {
    background-color: #f0f0f0;
  }
}
