.search {
  width: 95%;
  margin: 0 auto;

  input {
    width: 100%;
    display: block;
    margin: 18px 0;
    box-sizing: border-box;
    padding: 12px 18px;
    background: #FFFFFF;
    border: 1px solid #DFDFDF;
    border-radius: 15px;
    color: rgba(0, 0, 0, 0.56);
    outline: none;
  }

  .search__container {
    display: flex;
    align-items: center;
    justify-content: space-between;

    button {
      width: 18%;
      display: block;
      padding: 12px 18px;
      background: #2696D6;
      box-shadow: 2px 2px 15px rgba(0, 0, 0, 0.15);
      border-radius: 15px;
      box-sizing: border-box;
      color: #FFFFFF;
      font-size: 18px;
      line-height: 100%;
      border: none;
      cursor: pointer;
      transition: 0.3s;

      &:hover {
        box-shadow: 2px 2px 15px rgba(0, 0, 0, 0.242);
      }
    }
  }
}

@media(max-width: 960px) {
  .search {
    width: 90%;
  }
}

@media(max-width: 768px) {
  .search {
    width: 95%;

    input {
      width: 100%;
    }

    .search__container {
      flex-wrap: wrap;

      button {
        width: 55%;
        margin: 0 auto;
      }
    }
  }
}