.info__profit {
  margin: 32px 0;
  .info__profit-title {
    margin-bottom: 6px;
  }
  .info__profit-value {
    box-sizing: border-box;
    padding: 10px;
    font-size: 24px;
    border-radius: 20px;
    border: 1px solid #e6e6e6;
    width: 260px;
    text-align: center;
  }
}
