.authorization {
  width: 462px;
  margin: 92px auto;
  padding: 22px 12px;
  box-sizing: border-box;
  background: #FFFFFF;
  border-radius: 10px;
  box-shadow: 0px 20px 60px rgba(0, 0, 0, 0.1);

  .authorization__header {
    text-align: center;
    font-size: 24px;
    font-weight: 600;
    margin-bottom: 22px;
  }

  .authorization__employees {
    display: flex;
    width: 80%;
    justify-content: space-between;
    margin: 0 auto;
  }

  .authorization__employee {
    display: flex;
    width: 45%;
    margin: 8px 0;
    justify-content: center;
    padding: 12px 18px;
    cursor: pointer;
    box-sizing: border-box;
    background: #FFFFFF;
    border-radius: 12px;
    box-shadow: 0px 20px 60px rgba(0, 0, 0, 0.1);
    transition: 0.3s;

    a {
      text-decoration: none;
      color: #000000;
    }

    &:hover {
      box-shadow: 0px 20px 60px rgba(0, 0, 0, 0.225);
    }
  }

  .authorization__employee-active {
    background: #2696D6;
    color: #FFFFFF;
  }

  .authorization__button {
    width: 80%;
    padding: 8px;
    text-align: center;
    color: #FFFFFF;
    font-size: 22px;
    line-height: 100%;
    cursor: pointer;
    margin: 0 auto 9px auto;
    display: block;
    box-sizing: border-box;
    background: #2696D6;
    box-shadow: 2px 2px 15px rgba(0, 0, 0, 0.15);
    border-radius: 15px;
    transition: 0.3s;
    border: none;

    &:hover {
      box-shadow: 2px 2px 15px rgba(0, 0, 0, 0.261);
    }
  }

  label {
    margin: 18px auto;
    display: block;
    width: 80%;

    input {
      width: 100%;
      display: block;
      margin: 4px auto;
      box-sizing: border-box;
      padding: 8px 18px;
      background: #FFFFFF;
      border: 1px solid #DFDFDF;
      border-radius: 15px;
      color: rgba(0, 0, 0, 0.56);
    }
  }
}

.nav__sign-modal-input-yandex {
  color: white;
  background-color: black;
  font-size: 20px;
  display: flex;
  justify-content: space-around;
  align-items: center;
  padding: 12px;
  border-radius: 12px;
}

@media(max-width: 462px) {
  .authorization {
    width: 95%;
  }

  .authorization__employees {
    width: 100% !important;
  }

  .authorization__employee {
    width: 48% !important;
  }
}