.setting {
  padding: 26px 0;
}

.setting__block {
  display: flex;
  width: 60%;
  padding: 18px 0;
  justify-content: space-between;
  align-items: center;

  button {
    border: none;
    background: none;
    font-size: 16px;
    color: #4c75f2;
    cursor: pointer;
  }

  .setting__block-title {
    width: 152px;
  }

  form {
    display: flex;

    input {
      width: auto;
    }
  }

  span {
    display: flex;
  }
}

@media (max-width: 820px) {
  .setting__block {
    width: 100%;
  }
}

@media (max-width: 526px) {
  .setting__block {
    flex-wrap: wrap;
  }

  .setting__block-wrapper {
    width: 100%;
    display: flex;
    align-items: center;
    flex-wrap: wrap;
  }
}